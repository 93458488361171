import { createSelector } from 'reselect';
import { RootState } from '../../../../store/store';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { mapOrderSchedule } from '@modules/orders/utils/mapOrdersSchedule';
import { getLastOrderSelector } from '@modules/orders/core/selectors';

const getPending = (state: RootState) => state.orderSchedule.pending;

const getOrderSchedule = (state: RootState) => state.orderSchedule.data;

const getError = (state: RootState) => state.orderSchedule.error;

export const getOrderScheduleSelector = createSelector(
  [getOrderSchedule, getDefaultSubscriptionSelector, getLastOrderSelector],
  (orderSchedule, defaultSubscription, lastOrder) => {
    const unskippedOrderSchedule = orderSchedule.filter(
      (order: IOrder) => order?.status !== 'SKIPPED',
    );

    const finalSchedule = Boolean(lastOrder)
      ? unskippedOrderSchedule
      : unskippedOrderSchedule.slice(1);

    return mapOrderSchedule(finalSchedule, defaultSubscription);
  },
);

export const getCurrentOrderSelector = createSelector(
  [getOrderSchedule, getDefaultSubscriptionSelector, getLastOrderSelector],
  (orderSchedule, defaultSubscription, lastOrder) => {
    if (!orderSchedule.length && !lastOrder) return null;

    let currentOrder = null;

    if (lastOrder) {
      currentOrder = lastOrder;
    } else {
      const unprocessedOrderSchedule = orderSchedule.filter(
        (order: IOrder) => order?.status !== 'SKIPPED',
      );

      currentOrder = unprocessedOrderSchedule[0];
    }

    return mapOrderSchedule([currentOrder], defaultSubscription)?.[0];
  },
);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
