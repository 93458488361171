export const checkIfDateToday = (date: Date) => {
  const updatedDate = new Date(date);

  const currentDate = new Date();

  return (
    updatedDate.getFullYear() === currentDate.getFullYear() &&
    updatedDate.getMonth() === currentDate.getMonth() &&
    updatedDate.getDate() === currentDate.getDate()
  );
};
