import { checkIfDateToday } from '@helpers/checkIfDateToday';
import { createSelector } from 'reselect';
import { RootState } from 'src/store/rootReducer';

const getPending = (state: RootState) => state.orders.pending;

const getOrders = (state: RootState) => state.orders.data;

const getError = (state: RootState) => state.orders.error;

export const getOrdersSelector = createSelector(getOrders, (orders) => orders);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getLastOrderSelector = createSelector(getOrdersSelector, (orders) => {
  const lastOrder = orders?.[0];
  if (!lastOrder) return null;

  if (lastOrder.status !== 'PROCESSED') return null;

  if (lastOrder?.tracking?.state === 'delivered') {
    const trackingDelivered = lastOrder.tracking.events?.find(
      (event: TrackingEvent) => event.id === 'delivered',
    );

    if (!trackingDelivered?.updatedAt || !checkIfDateToday(trackingDelivered.updatedAt)) {
      return null;
    }
  }

  return lastOrder;
});

export const getPastOrdersSelector = createSelector(
  [getOrdersSelector, getLastOrderSelector],
  (orders, lastOrder) => {
    if (!lastOrder) return orders;
    else return orders.slice(1);
  },
);
