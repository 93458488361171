import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../shared/helpers/index';
import { toAbsoluteUrl } from '../../../../../shared/helpers/index';
import { useSelector } from 'react-redux';
import { getOrderScheduleSelector } from '@modules/order-schedule/core/selectors';
import { FormattedDate } from 'react-intl';
import { Widget } from '@components/index';

export type SingleOrderSummaryProps = {
  order: any;
};

export const UpcomingOrders = ({ order }: SingleOrderSummaryProps) => {
  const lineItem = order?.lines?.[0];

  const orders = useSelector(getOrderScheduleSelector);

  if (!orders.length) return <p>Keine Bestellung vorhanden.</p>;

  return (
    <Widget
      header={
        <p>
          Deine nächsten
          <span className="font-bold"> Bestellungen</span>
        </p>
      }
      footer={
        <Link to="/orders" className="order-link">
          Bestellungen Bearbeiten
          <KTSVG
            path={toAbsoluteUrl('/media/svg/general/link.svg')}
            className="w-[1.7rem] fill-primary"
          />
        </Link>
      }
    >
      <div className="flex items-center justify-center">
        <ul className="list-disc px-[2rem] py-[1rem]">
          {orders.slice(0, 3).map((order: any) => (
            <li key={order.id} className="py-[1rem] list-none">
              {lineItem?.productTitle}
              <br />
              <div className="flex gap-2 ">
                <span className="font-bold">Bestelldatum:</span>
                <FormattedDate
                  value={new Date(order?.billingDate)}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </div>

              <div className="flex gap-2">
                <span className="font-bold">Lieferadresse:</span> {order?.shippingAddress?.address1}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Widget>
  );
};
